// Nexa Fonts
// ===========================
@font-face {
  font-family: NexaRegular;
  src: url("/public/NexaWebFont/NexaRegular.otf") format("opentype");
}
@font-face {
  font-family: NexaBook;
  src: url("/public/NexaWebFont/NexaBook.otf") format("opentype");
}

@font-face {
  font-family: NexaBlack;
  src: url("/public/NexaWebFont/NexaBlack.otf") format("opentype");
}
