// UIkit Core
@import "../node_modules/uikit/src/less/components/_import.less";

// Custom Fonts
@import "aspire/_fonts.less";

// UIkit Theme
@import "master/_import.less";
@import "aspire/aspire-variables.less";

// Lowen Common Theme
@import "common-theme.less";
// TheDesigner Theme
@import "common-designer-theme.less";

// Branding
// ========================================================================
// make icon buttons Aspire charcoal
//@icon-button-background: #DCF763;
@icon-button-background: @global-secondary-background;
//@icon-button-color: @global-background;
@icon-button-color: @global-link-color;

@inverse-global-border: #fff;
//Designer variables
@offcanvas-background: @DarkJungleGreen;
@offcanvas-project-button: @Mindaro;

@project-button-hover: @Mindaro;
@project-button-hover-background: @DarkJungleGreen;
@project-button-hover-shadow: @Mindaro;

@texture-dropdown-background:@Charcoal;

@overlay-background: @Charcoal;
@overlay-background-expanded: @Charcoal;

@library-overlay-background: @DarkJungleGreen;
@library-button-container-background:@Mindaro;
@library-button-container:@DarkJungleGreen;
@library-button:@DarkJungleGreen;
@library-button-background: transparent;
#offcanvasNav {
  .mobileMarginTop {
    margin-top: .5em;
  }
}
// padding the button for new line height;
.uk-offcanvas-bar {
  background-color: @offcanvas-background;
  .uk-button-primary.project-button {
    background-color: @offcanvas-project-button;
  }
}
.uk-button {
    // line-height: 1.5;
    padding-top: 4px;
}

.uk-button-primary {
    outline: 2px solid @global-primary-background;
    outline-offset: 3px;
}

.uk-button-secondary {
    outline: 2px solid @global-secondary-background;
    outline-offset: 3px;
}

.uk-button-danger {
    outline: 2px solid @global-danger-background;
    outline-offset: 3px;
}

.uk-button-default {
    border: none;
    outline: 2px solid @global-primary-background;
    outline-offset: 3px;
}

.uk-button-success {
    background-color: rgb(26, 200, 26);
    color: white;
    outline: 2px solid rgb(26, 200, 26)!important;
    outline-offset: 3px;
  }
  
  .uk-button-success:hover,
  .uk-button-success:focus {
    background-color: @global-background;
    color: @global-primary-background;
    outline: 2px solid rgb(26, 200, 26)!important;
    outline-offset: 3px;
  }

.uk-button-default:hover {
    color: @Mindaro;
    background-color: @Charcoal;
}

.tile-menu-dropdown-option:hover {
    text-decoration-line: underline;
    text-underline-offset: 5px;
    text-decoration-thickness: 2px;
    text-decoration-color: @Mindaro;
}

.tile-menu-dropdown-option .unavailable {
    color: lighten(gray, 30%);
}

.tile-menu-dropdown-option .unavailable:hover {
    background-color: transparent!important;
}

.selection-span.uk-text-primary {
    text-decoration-line: underline;
    text-decoration-thickness: 3px;
    text-underline-offset: 3px;
    text-decoration-color: darken(@Mindaro, 20%);
}

#BillingDetails,
#ShippingDetails {
    .uk-form-controls {
        .uk-input::placeholder {
            color: @global-warning-background;
        }
    }
}